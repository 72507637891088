import { React } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VCard from "vcard-creator";
import backimage from "./asset/poster_background.jpg";
import carbon0ff from "./asset/carbon0ff.svg";
import whatsapp from "./asset/icon/whatsapp.svg";
import call from "./asset/icon/call.svg";
import mail from "./asset/icon/mail.svg";
import website from "./asset/icon/website.svg";
import location from "./asset/icon/location.svg";
import facebook from "./asset/connect_icon/facebook.svg";
import instagram from "./asset/connect_icon/instagram.svg";
import linkedin from "./asset/connect_icon/linkedin.svg";
import twitter from "./asset/connect_icon/twitter.svg";
import youtube from "./asset/connect_icon/youtube.svg";
import addContact from "./asset/fotter_icon/add_contact.svg";
import share from "./asset/fotter_icon/share.svg";

function Poster() {
  // Show toast
  const notify = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url)
      .then(() => toast.success('Link copied successfully🤗', {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }));
    
  };

  const handleSaveContact = () => {
    // Create a new vCard
    const myVCard = new VCard();
    console.log("sdmk");

    // Set contact information
    myVCard.addName("Logitech", "Evify");
    myVCard.addPhoneNumber(process.env.REACT_APP_MOBILE_NO, "WORK");
    myVCard.addPhoneNumber(process.env.REACT_APP_MOBILE_NO_2, "PREF;WORK");
    myVCard.addEmail(process.env.REACT_APP_MAIL);
    myVCard.addAddress(
      null,
      null,
      process.env.REACT_APP_ADDRESS,
      process.env.REACT_APP_ADDRESS_POSTAL_CODE,
      process.env.REACT_APP_ADDRESS_COUNTRY
    );
    myVCard.addURL(process.env.REACT_APP_WEBSITE);
    myVCard.addCompany("Evify Logitech");
    myVCard.addSocial(
      process.env.REACT_APP_LINKEDIN,
      "Linkedin",
      "desloovere_j"
    );

    // Get the vCard data as a string
    const vCardData = myVCard.toString();

    // Create a blob with the vCard data
    const blob = new Blob([vCardData], { type: "text/vcard" });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contact.vcf");

    // Simulate a click on the link element to trigger the download
    link.click();
  };

  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col justify-center items-center w-[100vw] max-w-[430px]">
        <div className=" inline-block  ">
          <img src={backimage} alt="backgroung image" />
        </div>
        <div className="flex justify-center my-7 items-center">
          <img src={carbon0ff} alt="Carbon Off" />
        </div>

        {/* Touch option */}
        <div className=" flex flex-col items-center  w-full justify-evenly h-40 mb-7 text-white font-medium bg-[#01C3C3]">
          <p className="pb-4 text-lg">Get In Touch</p>

          <div className="flex justify-evenly w-full ">
            <div
              onClick={() =>
                window.open(
                  process.env.REACT_APP_WHATSAPP,
                  "_blank",
                  "rel=noopener noreferrer"
                )
              }
              className="flex justify-center flex-col items-center text-xs	"
            >
              <button className="bg-white rounded-full p-3 flex justify-center items-center w-11 h-11 ">
                <img src={whatsapp} alt="backgroung image" />
              </button>
              <p>Whatsapp</p>
            </div>
            <div className="flex justify-center flex-col items-center text-xs	">
              <button
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_CALL,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                className="bg-white rounded-full p-3 flex justify-center items-center w-11 h-11 "
              >
                <img src={call} alt="backgroung image" />
              </button>
              <p>Call</p>
            </div>
            <div className="flex justify-center flex-col items-center text-xs	">
              <button
                onClick={() =>
                  window.open(
                    "mailto:" + process.env.REACT_APP_MAIL,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                className="bg-white rounded-full p-3 flex justify-center items-center w-11 h-11 "
              >
                <img src={mail} alt="backgroung image" />
              </button>
              <p>Email</p>
            </div>
            <div className="flex justify-center flex-col items-center text-xs	">
              <button
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_WEBSITE,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                className="bg-white rounded-full p-3 flex justify-center items-center w-11 h-11 "
              >
                <img src={website} alt="backgroung image" />
              </button>
              <p>Website</p>
            </div>
            <div className="flex justify-center flex-col items-center text-xs	">
              <button
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_LOCATION,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                className="bg-white rounded-full p-3 flex justify-center items-center w-11 h-11 "
              >
                <img src={location} alt="backgroung image" />
              </button>
              <p>Location</p>
            </div>
          </div>
        </div>

        {/* Connect with us */}
        <div className=" flex flex-col items-center  w-full justify-evenly h-40 mb-7 text-white font-medium bg-[#FFB603]">
          <p className="text-lg pb-4 text-lg">Connect With Us</p>

          {/* Connect with us */}
          <div className="flex justify-evenly w-full ">
            <div className="flex justify-center flex-col items-center text-xs	">
              <button
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_FACEBOOK,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                className="bg-white rounded-full p-3 flex justify-center items-center w-11	 h-11 "
              >
                <img src={facebook} alt="backgroung image" />
              </button>
              <p>Facebook</p>
            </div>
            <div className="flex justify-center flex-col items-center text-xs	">
              <button
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_INSTAGRAM,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                className="bg-white rounded-full p-3 flex justify-center items-center w-11	 h-11 "
              >
                <img src={instagram} alt="backgroung image" />
              </button>
              <p>Instagram</p>
            </div>
            <div className="flex justify-center flex-col items-center text-xs	">
              <button
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_LINKEDIN,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                className="bg-white rounded-full p-3 flex justify-center items-center w-11	 h-11 "
              >
                <img src={linkedin} alt="backgroung image" />
              </button>
              <p>linkedin</p>
            </div>
            <div className="flex justify-center flex-col items-center text-xs	">
              <button
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_TWITTER,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                className="bg-white rounded-full p-3 flex justify-center items-center w-11	 h-11 "
              >
                <img src={twitter} alt="backgroung image" />
              </button>
              <p>Twitter</p>
            </div>
            <div className="flex justify-center flex-col items-center text-xs	">
              <button
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_YOUTUBE,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                className="bg-white rounded-full p-3 flex justify-center items-center w-11	 h-11 "
              >
                <img src={youtube} alt="backgroung image" />
              </button>
              <p>Youtube</p>
            </div>
          </div>
        </div>

        <div className=" flex flex-col items-center px-4  justify-evenly w-full h-56 mb-7 text-white font-medium bg-[#01C3C3] ">
          <p className="border-2 rounded-full w-[80px] h-[30px] text-center border-white text-base">
            About
          </p>
          <p className="font-normal">
            To deploy our electric fleet to all the E-comm giants and
            decarbonise last mile logistics sector in Tier II and Tier III
            cities of india by 2030. To create a complete ecosystem for the LML
            sector in all the Tier II and III cities of india.
          </p>
        </div>

        {/* Contact */}
        <div className=" flex items-center px-4  justify-evenly h-24 w-full  text-white font-medium bg-[#FFB603] ">
          <button
            onClick={handleSaveContact}
            className="bg-white rounded-full p-3 flex justify-around items-center w-32 h-11  text-[#01C3C3] text-sm "
          >
            <img src={addContact} alt="backgroung image" className="h-4" />{" "}
            <p>Add contact</p>
          </button>
          <button
            onClick={notify}
            className="bg-white rounded-full p-3 flex justify-around items-center w-32 h-11 text-[#01C3C3] text-sm  "
          >
            <img src={share} alt="backgroung image" className="h-4" />{" "}
            <p>Share Profile</p>
          </button>
        </div>

        <div className="h-12 flex flex-col items-center w-full justify-evenly">
          <p className="text-sm">
            Designed By{" "}
            <button
              onClick={() =>
                window.open(
                  process.env.REACT_APP_SAPIENT_WEBSITE,
                  "_blank",
                  "rel=noopener noreferrer"
                )
              }
              className="font-bold text-sm"
            >Sapient Codelabs
            </button>
          </p>
          <hr className="w-28 border border-[#FFB603]" />
        </div>
      </div>
    </div>
  );
}

export default Poster;
