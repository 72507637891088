import React, { Fragment } from 'react';
import Poster from './Poster'

function App() {
  return (
    <Fragment>
      <Poster></Poster>
    </Fragment>
  );
}

export default App;
